<template>
  <v-container fluid>
    <v-row
      justify="space-between"
      align="center"
      class="mb-2"
    >
      <v-col cols="auto">
        <h4 class="d-inline mr-2">{{$_strings.invoice.TITLE_PAGE_SHIPPER}}</h4>
        <v-btn
          color="primary"
          elevation="1"
          class="mr-4"
          small
          @click="createInvoice"
          v-if="userAccess.canCreate"
        >
          {{$_strings.invoice.ADD_TEXT}}
        </v-btn>
      </v-col>
      <v-col cols="12" sm="5" md="3">
        <v-text-field
          dense
          hide-details
          outlined
          class="caption"
          :placeholder="$_strings.invoice.SEARCHNO"
          v-model="searchText"
          @keyup.enter="setFilter"
        >
          <template v-slot:prepend-inner>
            <v-icon @click="setFilter">
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-sheet>
      <FilterBy
        :filter="filter"
        @setFilter="setFilter"
      />
      <v-data-table
        :loading="isLoading"
        :headers="headers"
        calculate-widths
        :items="itemsInvoice"
        item-key="invoiceNo"
        :server-items-length="invoiceListTotalEntry"
        :options.sync="pagination"
        @click:row="rowClicked"
        :item-class="itemRowIsEdit"
        :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
      >
        <template v-slot:[`item.invoiceNo`]=items>
          <span>{{ items.item.invoiceNo }}</span>
          <br/>
          <span>{{ dateFormat(items.item.invoiceDate) }}</span>
        </template>
        <template v-slot:[`item.billedToCompanyName`]=items>
          <span>{{ items.item.billedToCompanyName }}</span>
        </template>
        <template v-slot:[`item.periodStart`]=items>
          <span>{{ countMonths(items.item.periodStart, items.item.periodEnd) }} Bulan</span>
          <br/>
          <span class="size-12">{{ dateFormat(items.item.periodStart) }} - {{ dateFormat(items.item.periodEnd) }}</span>
        </template>
        <template v-slot:[`item.lokasiAsal`]=items>
          <span>{{ items.item.lokasiAsal ? items.item.lokasiAsal : '-' }}</span>
        </template>
        <template v-slot:[`item.lokasiTujuan`]=items>
          <span>{{ items.item.lokasiTujuan ? items.item.lokasiTujuan : '-' }}</span>
        </template>
        <template v-slot:[`item.totalAmount`]=items>
          <span>Rp. {{ formatAmount(items.item.totalAmount) }}</span>
          <br/>
          <span class="size-12" v-if="items.item.invoiceType === 'Pengiriman'">{{ items.item.totalShipment }} Pesanan</span>
          <span class="size-12" v-else>{{ items.item.totalShipment }} Biaya</span>
        </template>
        <template v-slot:[`item.invoiceType`]=items>
          <span>{{ items.item.invoiceType }}</span>
        </template>
        <template v-slot:[`item.createdBy`]=items>
          <span>{{ items.item.createdBy }}</span>
          <br/>
          <span class="size-12">{{ dateFormat(items.item.createdAt) }}</span>
        </template>
        <template v-slot:[`item.status`]="{item}">
          <v-select
            class="mt-3 pa-0 text-caption"
            style="width: 10rem"
            outlined
            dense
            :items="itemsStatus(item)"
            item-value="text"
            item-text="text"
            v-model="item.status"
            @change="changeStatus(item, $event)"
            @click.stop.prevent>
          </v-select>
        </template>
        <template v-slot:[`item.actionColumn`]="{item}">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                x-small
                class="mr-2"
                color="primary"
                @click="exportPdf(item)"
                @click.stop.prevent>
                <v-icon>mdi-file-pdf-box</v-icon>
              </v-btn>
            </template>
            <span>File PDF</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="item.status === 'Proforma'"
                v-bind="attrs"
                v-on="on"
                x-small
                icon
                class="mr-2"
                color="primary"
                @click="exportExcel(item)"
                @click.stop.prevent>
                <v-icon> mdi-file-excel-box </v-icon>
              </v-btn>
            </template>
            <span>File XLS</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="item.status === 'Proforma'"
                v-bind="attrs"
                v-on="on"
                x-small
                icon
                class="mr-2"
                color="primary"
                @click="modalEmail(item)"
                @click.stop.prevent >
                <v-icon> mdi-email </v-icon>
              </v-btn>
            </template>
            <span>Email</span>
          </v-tooltip>
        </template>
        <template v-slot:[`footer.page-text`]="props">
          <span>
            {{$_strings.invoice.INVOICE_LIST}}
            <span v-if="itemsInvoice.length">
              {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
            </span>
          </span>
        </template>
      </v-data-table>
    </v-sheet>
    <!-- Button Dialog Email -->
    <v-row>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="700"
      >
        <v-card>
          <v-card-title>
            <span class="headtitle-email">Masukan Email Tujuan</span>
            <v-row>
              <v-col class="d-flex justify-end">
                <v-toolbar-items>
                  <v-btn
                    icon
                    color="black"
                    dark
                    @click="closeModal()"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-col>
            </v-row>
          </v-card-title>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row>
                <v-col>
                  <v-subheader>Email</v-subheader>
                </v-col>
                <v-col
                  cols="10"
                  class="mr-4"
                >
                  <v-autocomplete
                    outlined
                    dense
                    :items="getEmail"
                    :search-input.sync="searchEmail"
                    item-value="email"
                    item-text="email"
                    v-model="form.email"
                    multiple
                    chips
                    persistent-hint
                  >
                    <template v-slot:selection="{ attrs, item, select, selected }">
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="removeEmail(item)"
                      >
                        {{ item.email }}<br>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      <span>{{item.email}}</span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-btn
                  outlined
                  small
                  class="mr-2 ml-auto"
                  @click="closeModal()"
                >
                  Batal
                </v-btn>
                <v-btn
                  depressed
                  small
                  color="primary"
                  class="white--text"
                  @click="submitEmail"
                  :loading="submitingEmail"
                >
                  Simpan
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
    <DialogDateReceived
      ref="DialogDateReceived"
      @updateStatusInvoice="updateStatusInvoice"
      @fetchInvoice="fetchInvoice"
      :invoiceDate="periodStart"
    />
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import DialogDateReceived from './Dialog/DateReceived.vue';
import FilterBy from './FilterBy.vue';
import { dateFormat, thousandSeparated } from '../../helper/commonHelpers';

export default {
  components: {
    DialogDateReceived,
    FilterBy,
  },
  name: 'InvoiceList',
  created() {
    this.getCompanyList();
  },
  data() {
    return {
      isLoading: false,
      itemsInvoice: [],
      searchText: this.$route.query.search || '',
      valid: false,
      invoiceListPage: 1,
      invoiceListTotalEntry: 0,
      companyShipperId: this.$route.query.companyShipperId || null,
      form: {
        email: [],
      },
      headers: [
        {
          text: this.$_strings.invoice.INVOICE_NO,
          value: 'invoiceNo',
          class: 'white--text primary text-capitalize',
          width: '150px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.invoice.SHIPPER_NAME,
          value: 'billedToCompanyName',
          class: 'white--text primary text-capitalize',
          width: '180px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.invoice.ORDER_PERIODE,
          value: 'periodStart',
          class: 'white--text primary text-capitalize',
          width: '200px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.order.ORIGIN,
          value: 'lokasiAsal',
          class: 'white--text primary text-capitalize',
          width: '200px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.order.DESTINATION,
          value: 'lokasiTujuan',
          class: 'white--text primary text-capitalize',
          width: '200px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.invoice.INVOICE_TOTAL,
          value: 'totalAmount',
          class: 'white--text primary text-capitalize',
          width: '150px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.invoice.INVOICE_TYPE,
          value: 'invoiceType',
          class: 'white--text primary text-capitalize',
          width: '180px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.invoice.CREATED_BY,
          value: 'createdBy',
          class: 'white--text primary text-capitalize',
          width: '180px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.invoice.STATUS,
          value: 'status',
          class: 'white--text primary text-capitalize',
          width: '150px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.invoice.ACTION,
          value: 'actionColumn',
          align: 'left',
          class: 'white--text primary text-capitalize',
          sortable: false,
          width: '10vw',
        },
      ],
      filterStatusBy: this.$_strings.invoice.FILTER_STATUS_LIST,
      filterTypeBy: this.$_strings.invoice.FILTER_TYPE_LIST,
      expanded: [],
      pagination: {
        itemsPerPage: +this.$route.query.itemsPerPage || 10,
        page: +this.$route.query.page || 1,
        sortBy: [],
        sortDesc: [],
      },
      itemsCompany: [],
      dialog: false,
      getEmail: [],
      searchEmail: null,
      getCompany: [],
      statusChange: '',
      invoiceId: '',
      invoiceStatusId: '',
      companyId: '',
      periodStart: '',
      submitingEmail: false,
      filter: {
        billedToCompanyId: +this.$route.query.billedToCompanyId || '',
        dateFrom: this.$route.query.dateFrom || dayjs().subtract(7, 'days').format('YYYY-MM-DD'),
        dateTo: this.$route.query.dateTo || dayjs().format('YYYY-MM-DD'),
        statusInvoice: this.$route.query.statusInvoice || '',
        typeInvoice: this.$route.query.typeInvoice || '',
      },
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        const { page, itemsPerPage } = this.$route.query;
        if (+page !== newVal.page || +itemsPerPage !== newVal.itemsPerPage) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              page: newVal.page,
              itemsPerPage: newVal.itemsPerPage,
            },
          });
        }
        this.fetchInvoice();
      },
      deep: true,
    },
    searchEmail(val) {
      if (!this.companyId) return;
      if (val === null) {
        this.$_services.invoice.emailList(this.companyId)
          .then((res) => {
            this.getEmail = res.data.contents;
          });
      } else {
        this.$_services.invoice.emailSearchList(val, this.companyId)
          .then((res) => {
            this.getEmail = res.data.contents;
          });
      }
    },
  },
  methods: {
    dayjs,
    setFilter() {
      if (this.$route.query.search !== this.searchText) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            search: this.searchText,
          },
        });
      }
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
        return;
      }
      this.fetchInvoice();
    },
    itemRowIsEdit(item) {
      if (item.status === 'Proforma') {
        return 'pointer';
      }
    },
    rowClicked(e, { item }) {
      if (item.status === 'Proforma') {
        const params = {
          invoiceId: item.id,
        };
        this.$router.push({
          name: 'invoice-edit',
          params,
        });
      }
    },
    createInvoice() {
      this.$router.push('/main/invoice-customer/create');
    },
    searchShipperCompany(event) {
      this.$_services.invoice.companySearchShipper(event.target.value)
        .then((res) => {
          this.getCompany = res.data.contents;
        });
    },
    removeEmail(item) {
      this.form.email.splice(this.form.email.indexOf(item), 1);
      this.form.email = [...this.form.email];
    },
    fetchInvoice() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        name: this.searchText,
        sort: '',
        companyType: 'SHIPPER',
        ...this.filter,
      };
      if (sortBy.length > 0) {
        const isDesc = sortDesc[0] ? 'DESC' : 'ASC';
        filters.sort = `${sortBy[0]},${isDesc}`;
      }
      this.isLoading = true;
      this.$_services.invoice.fetchInvoice(filters)
        .then((result) => {
          this.itemsInvoice = result.data.contents;
          this.invoiceListTotalEntry = result.data.totalData;
          this.invoiceListPage = result.data.page;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getEmailList() {
      this.$_services.invoice.emailList(this.companyId)
        .then((res) => {
          this.getEmail = res.data.contents;
        }).finally(() => {
          this.$root.$loading.hide();
        });
    },
    getCompanyList() {
      this.$_services.invoice.companySearchShipper()
        .then((res) => {
          this.getCompany = res.data.contents;
        }).finally(() => {
          this.$root.$loading.hide();
        });
    },
    modalEmail(item) {
      this.dialog = true;
      this.invoiceId = item.id;
      this.companyId = item.billedToCompanyId;
      this.getEmailList();
    },
    closeModal() {
      this.dialog = false;
      this.invoiceId = '';
      this.companyId = '';
    },
    itemsStatus(item) {
      let statusItems = this.filterStatusBy;
      if (!item.isIntegratedOracle) statusItems = this.filterStatusBy.filter((i) => i.value !== 'SEND_ORACLE' && i.value !== 'HALF_PAID');
      const ind = statusItems.findIndex((e) => e.text === item.status);
      const data = [];
      statusItems.forEach((i, index) => {
        if ((index === ind || index === ind + 1) && i.value !== 'HALF_PAID') {
          data.push({
            ...i,
          });
          return;
        }
        if (statusItems[ind + 1] && statusItems[ind + 1].value === 'HALF_PAID' && index === ind + 2) {
          data.push({
            ...i,
            disabled: false,
          });
          return;
        }
        data.push({
          ...i,
          disabled: true,
        });
      });
      return data;
    },
    changeStatus(item, event) {
      this.periodStart = dayjs(item.periodStart).format('YYYY-MM-DD');
      this.statusChange = this.filterStatusBy.find((i) => i.text === event).value;
      this.invoiceStatusId = item.id;
      if (event === 'Sudah diterima' || event === 'Sudah dibayar' || event === 'Sudah dikirim') {
        this.$refs.DialogDateReceived.minDate = dayjs(item.createdAt).format();
        this.$refs.DialogDateReceived.dialog = true;
        return;
      }
      this.updateStatusInvoice();
    },
    updateStatusInvoice(date = '') {
      this.$root.$loading.show();
      this.$_services.invoice.changeStatusInvoice(this.invoiceStatusId, this.statusChange, date)
        .then(() => {
          this.$dialog.notify.success(this.$_strings.invoice.EDIT_STATUS_MESSAGE_TEXT);
        }).finally(() => {
          this.$root.$loading.hide();
          this.fetchInvoice();
        });
    },
    countMonths(start, end) {
      const sDate = new Date(start).getMonth();
      const eDate = new Date(end).getMonth();
      const sYear = new Date(start).getFullYear() * 12;
      const eYear = new Date(end).getFullYear() * 12;
      const difference = (eYear + eDate) - (sYear + sDate);
      if (difference === 0) {
        return 1;
      }
      return difference;
    },
    dateFormat,
    formatAmount(amount) {
      return thousandSeparated(amount);
    },
    exportPdf(item) {
      if (item.invoiceNo) {
        this.$_services.invoice.exportPdfInvoice(item.id).then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          const { invoiceNo } = item;
          const invoiceLink = invoiceNo.replace(/\//g, '_');
          link.download = `invoice-${invoiceLink}.pdf`;
          link.click();
          URL.revokeObjectURL(link.href);
        }).catch(console.error);
        return;
      }
      const parameter = {
        invoiceId: item.id,
        invoiceType: 'MANAGEMENT',
        exportFile: 'PDF',
      };
      this.$_services.invoice.exportPdfInvoiceProforma(parameter).then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'invoice-proforma.pdf';
        link.click();
        URL.revokeObjectURL(link.href);
      }).catch(console.error);
    },
    exportExcel(item) {
      const parameter = {
        invoiceId: item.id,
        invoiceType: 'MANAGEMENT',
        exportFile: 'EXCEL',
      };
      this.$_services.invoice.exportPdfInvoiceProforma(parameter).then((response) => {
        const blob = new Blob([response.data], { type: 'application/xls' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'invoice-proforma.xls';
        link.click();
        URL.revokeObjectURL(link.href);
      }).catch(console.error);
    },
    submitEmail() {
      if (this.form.email.length < 1) {
        this.$dialog.notify.error('Mohon periksa kembali field yang kosong');
        return;
      }
      this.submitingEmail = true;
      this.$_services.invoice.sendEmail(this.form.email, this.invoiceId)
        .then((res) => {
          this.$dialog.notify.success('Berhasil mengirimkan proforma ke email.');
        }).finally(() => {
          this.submitingEmail = false;
          this.dialog = false;
          this.$refs.form.reset();
          this.fetchInvoice();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .v-chip.v-size--default {
    font-size: 12px;
    margin-top: 3px;
  }
  .size-12 {
    font-size: 12px;
  }
  .size-10 {
    font-size: 10px;
  }
  .pointer {
    cursor: pointer;
  }
</style>
